





























































































import ICrudClient from "@/lib/ICrudClient";
import { Role } from "@/data/models/Roles";
import { UserGroup } from "@/data/models/UserGroups";
import { User } from "@/data/models/Users";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import EntitySelect from "@/components/EntitySelect.vue";

@Component({
  components: {
    FormContainer,
    Loader,
    EntitySelect
  }
})
export default class UserForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public provider!: DataProvider<User>;

  @Prop()
  public crud!: ICrudClient<User>;

  @Prop()
  public groupsProvider!: DataProvider<UserGroup>;

  @Prop()
  public rolesProvider!: DataProvider<Role>;

  private item: any = null;

  async mounted() {
    if (this.id === "new") {
      this.item = {
        email: "",
        firstName: "",
        lastName: "",
        isAdmin: false,
        userGroupId: null,
        isLawyer: false,
        isPsychologist: false,
        programs: [],
        emailConfirmed: false
      };
    }
  }

  async sendActivation() {
    await this.$service.sendUserActivationEmail(this.id);
  }
}
